import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import TextSetValue from 'ui-kit/text/textSetValue';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { MembershipSettingsErrorModal } from 'display-components/membership/modals';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import ChangePaymentMethod from 'components/change-payment-method/change-payment-method';
import PaymentInformation from 'components/checkout/payment-information';
import { ModalComponentContent, ModalComponentFooter, ModalComponentHeader } from 'components/modal/modal.component';
import { PaymentAddNew } from 'components/payment-add-new/payment-add-new';

import { accountFetchProfileRoutine, accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import { accountCreditCardsSelector, accountIsMembershipSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { closeModalComponent, setModalStep } from 'state/modal/modal.reducer';
import { modalComponentStepSelector } from 'state/modal/modal.selector';
import { paymentsActions } from 'state/payments/payments.reducers';
import { paymentsV2PostPatientBalanceFamilyRoutine } from 'state/payments/payments.routines';
import { paymentsSelectedMethodSelector } from 'state/payments/payments.selectors';

import { convertToTitleCase } from 'util/string';

import { MakePaymentProps } from './make-payment-modal.props';
import './make-payment-modal.styles.scss';

const MakePayment = ({ paymentInformation, fetchPaymentData }: MakePaymentProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isCaregiver, amountDue, dependentName, epostPatientNum, isTotalPayment } = paymentInformation;

    const [selectedPaymentType, setSelectedPaymentType] = useState<'full' | 'other'>('full');
    const [amountValue, setAmountValue] = useState<string | undefined>(undefined);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [remainingAmount, setRemainingAmount] = useState<number | undefined>(undefined);
    const [inputTouched, setInputTouched] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const allPaymentData = useSelector(accountCreditCardsSelector);
    const isMembership = useSelector(accountIsMembershipSelector);
    const selectedPayment = useSelector(paymentsSelectedMethodSelector);
    const currentModalStep = useSelector(modalComponentStepSelector);

    const isOverPaying = Number(amountValue) > amountDue;

    useEffect(() => {
        if (allPaymentData === undefined) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, [allPaymentData, dispatch]);

    const defaultCreditCard = useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0
            ? allPaymentData.find((card) => card.defaultCard)
            : undefined;
    }, [allPaymentData]);

    useEffect(() => {
        if (defaultCreditCard !== undefined && selectedPayment === undefined) {
            dispatch(paymentsActions.setSelectedPaymentMethod(defaultCreditCard));
        }
    }, [defaultCreditCard, selectedPayment]);

    useEffect(() => {
        if (selectedPaymentType === 'full') {
            setAmountValue(amountDue.toFixed(2));
            setHasError(false);
            setRemainingAmount(undefined);
            setInputTouched(false);
        } else {
            setAmountValue(undefined);
        }
    }, [selectedPaymentType, amountDue]);

    useEffect(() => {
        if (selectedPaymentType === 'other') {
            const numericalValue = parseFloat(amountValue || '0');
            if (numericalValue > amountDue || numericalValue <= 0 || isNaN(numericalValue)) {
                setHasError(true);
                setRemainingAmount(undefined);
                setErrorMessage('pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.inputEmpty');
            } else {
                setHasError(false);
                setRemainingAmount(amountDue - numericalValue);
            }
        }
    }, [selectedPaymentType, amountValue, amountDue]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;

        // Allow only numbers and a single decimal point
        value = value.replace(/[^0-9.]/g, '');

        // Prevent entering more than one decimal point
        const parts = value.split('.');
        if (parts.length > 2) {
            value = `${parts[0]}.${parts.slice(1).join('').replace(/\./g, '')}`; // Keep the first decimal point and remove others
        }

        // Limit to two decimal places if there is a decimal point
        if (parts[1] && parts[1].length > 2) {
            value = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }

        // Allow only one leading zero
        if (value.startsWith('00')) {
            value = value.replace(/^0+/, '0.'); // Replace multiple leading zeros with a single zero
        }

        // Allow only one leading zero if the input starts with a decimal point
        if (value.startsWith('.')) {
            value = value.replace(/^.+/, '0.'); // Replace leading decimal point with '0.'
        }

        setAmountValue(value);
        setInputTouched(true);
    };

    const handleContinue = () => {
        setIsBusy(true);

        const dispatchPayment = (paymentAmount: number) => {
            dispatch(
                paymentsV2PostPatientBalanceFamilyRoutine.trigger({
                    CardSeqNum: selectedPayment?.cardSeqNum,
                    IsFamilyPayment: isTotalPayment,
                    PaymentAmount: paymentAmount,
                    CurrentBalance: amountDue,
                    PaymentEpostPatientNum: epostPatientNum,
                    onSuccess: () => {
                        setIsBusy(false);
                        dispatch(accountFetchProfileRoutine.trigger());
                        fetchPaymentData();
                        dispatch(closeModalComponent());
                        dispatch(paymentsActions.setSelectedPaymentMethod());
                    },
                    onFailure: () => {
                        setIsBusy(false);
                        dispatch(closeModalComponent());
                        dispatch(paymentsActions.setSelectedPaymentMethod());
                        showGenericErrorModal();
                        setHasError(true);
                        setErrorMessage(t('payBalanceForm.unkownError'));
                        fetchPaymentData();
                    }
                })
            );
        };

        if (selectedPaymentType === 'full') {
            dispatchPayment(amountDue);
        } else if (selectedPaymentType === 'other') {
            const numericalValue = parseFloat(amountValue || '0');

            // Validate the payment amount
            if (numericalValue <= 0 || isNaN(numericalValue) || numericalValue > amountDue) {
                setHasError(true);
                setIsBusy(false); // Reset busy state on error
                return;
            }

            dispatchPayment(numericalValue);
        }
    };

    const renderToastBox = (message: string, remainingAmount?: number) => {
        const translationOptions = remainingAmount ? { remaningAmount: remainingAmount.toFixed(2) } : undefined;

        return (
            <ToastBox variant="warning" icon="warning">
                <span
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(t(message, translationOptions), {
                            allowedTags: ['strong']
                        })
                    }}
                />
            </ToastBox>
        );
    };

    const displayToast = () => {
        if (!inputTouched) {
            return null;
        }

        const hasRemainingAmount = remainingAmount !== undefined && remainingAmount > 0;

        if (isOverPaying) {
            return renderToastBox(
                'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.toast.overPaying'
            );
        } else if (hasRemainingAmount && isMembership) {
            return renderToastBox(
                'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.toast.birdiCashPatients',
                remainingAmount
            );
        } else if (hasRemainingAmount && !isMembership) {
            return renderToastBox(
                'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.toast.insured',
                remainingAmount
            );
        } else {
            return null;
        }
    };

    const getErrorMessage = () => {
        if (!hasError) return '';

        if (amountValue === '') {
            return t(errorMessage);
        }

        if (isOverPaying) {
            return ' ';
        }

        return '';
    };

    const showGenericErrorModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => dispatch(closeModal({})),
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('components.membershipModals.errorTitle')} icon="alert" />
                ),
                bodyContent: (
                    <MembershipSettingsErrorModal
                        variation={'paymentError'}
                        memberName={''}
                        t={t}
                        onClose={() => dispatch(closeModal({}))}
                    />
                ),
                ctas: []
            })
        );
    }, [dispatch, t]);

    const getModalTitle = () => {
        switch (currentModalStep) {
            case 0:
                return dependentName
                    ? t('pages.profile.payment.modal.makePayment.titleDependent', {
                          patientName: convertToTitleCase(dependentName)
                      })
                    : t('pages.profile.payment.modal.makePayment.title');
            case 1:
                return t('pages.profile.membership.manageMembership.modals.changePaymentTitle');
            case 2:
                return t('pages.profile.membership.manageMembership.addNewPayment');
            default:
                return t('pages.profile.payment.modal.makePayment.title');
        }
    };

    const getModalContent = () => {
        switch (currentModalStep) {
            case 0:
                return (
                    <div className="make-payment-modal">
                        <h3 className="title">
                            {isCaregiver
                                ? t('pages.profile.payment.modal.makePayment.caregiverDescription')
                                : t('pages.profile.payment.modal.makePayment.description')}
                        </h3>
                        <div className="amount-to-pay">
                            <div className="amount-options">
                                <span className="amount-options-title">
                                    {t('pages.profile.payment.modal.makePayment.amountToPay.title')}
                                </span>
                                {isCaregiver ? (
                                    <div className="family-balance">
                                        <div className="family-balance-header">
                                            <span className="family-balance-label">
                                                {t(
                                                    'pages.profile.payment.modal.makePayment.amountToPay.options.familyBalance.title'
                                                )}
                                            </span>
                                            <span className="family-balance-amount">${amountDue.toFixed(2)}</span>
                                        </div>
                                        <ToastBox variant="info" icon="info">
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        t(
                                                            'pages.profile.payment.modal.makePayment.amountToPay.options.familyBalance.disclaimer'
                                                        )
                                                    )
                                                }}
                                            />
                                        </ToastBox>
                                    </div>
                                ) : (
                                    <>
                                        <div className="total">
                                            <label className="total-label">
                                                <input
                                                    type="radio"
                                                    name="total-amount"
                                                    value="full"
                                                    checked={selectedPaymentType === 'full'}
                                                    onChange={() => setSelectedPaymentType('full')}
                                                />
                                                {t(
                                                    'pages.profile.payment.modal.makePayment.amountToPay.options.fullBalance'
                                                )}
                                            </label>
                                            <span className="total-amount">${amountDue.toFixed(2)}</span>
                                        </div>
                                        <div className="other">
                                            <label className="other-label">
                                                <input
                                                    type="radio"
                                                    name="other-amount"
                                                    value="other"
                                                    checked={selectedPaymentType === 'other'}
                                                    onChange={() => setSelectedPaymentType('other')}
                                                />
                                                {t(
                                                    'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.label'
                                                )}
                                            </label>
                                            {selectedPaymentType === 'other' && (
                                                <TextSetValue
                                                    showErrorsText
                                                    name="other-amount-input"
                                                    label={t(
                                                        'pages.profile.payment.modal.makePayment.amountToPay.options.otherAmount.inputPlaceolder'
                                                    )}
                                                    onChange={onChangeHandler}
                                                    type="currency"
                                                    autocomplete="off"
                                                    value={amountValue || ''}
                                                    touched={inputTouched}
                                                    errors={getErrorMessage()}
                                                    disabled={isBusy}
                                                />
                                            )}
                                            {displayToast()}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <PaymentInformation
                            shouldHidePaymentType
                            skeletonHeight={200}
                            isLoading={!defaultCreditCard}
                            onChange={() => dispatch(setModalStep({ modalStep: 1 }))}
                            selectedPaymentMethod={selectedPayment || defaultCreditCard}
                            title={t('pages.profile.payment.modal.makePayment.paymentInfo.title')}
                        />
                    </div>
                );
            case 1:
                return (
                    <ChangePaymentMethod
                        isModal
                        selectedPayment={selectedPayment || defaultCreditCard}
                        currentFlow="payment-history"
                    />
                );
            case 2:
                return <PaymentAddNew mode="account" isOnModal currentFlow="payment-history" />;
            default:
                return <></>;
        }
    };

    const handleOnNavigate = () => {
        const newStep = currentModalStep === 1 ? 0 : 1;
        dispatch(setModalStep({ modalStep: newStep }));
    };

    return (
        <ModalComponentContent>
            <ModalComponentHeader
                hasDefaultTitle
                isCloseable
                hasTitleNavigation={currentModalStep !== 0}
                title={getModalTitle()}
                onNavigate={() => handleOnNavigate()}
                onClose={() => {
                    dispatch(closeModalComponent());
                    dispatch(paymentsActions.setSelectedPaymentMethod());
                }}
            />

            {getModalContent()}

            {currentModalStep === 0 && (
                <ModalComponentFooter
                    cancelButtonLabel={t('pages.profile.payment.modal.makePayment.buttons.cancel')}
                    onCancel={() => dispatch(closeModalComponent())}
                    continueButtonLabel={t('pages.profile.payment.modal.makePayment.buttons.makePayment')}
                    onContinue={handleContinue}
                    isCTABusy={isBusy}
                    isCTADisabled={hasError || (selectedPaymentType === 'other' && amountValue === '') || isBusy}
                />
            )}
        </ModalComponentContent>
    );
};

export default MakePayment;
