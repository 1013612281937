import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BalanceByMemberList from 'display-components/balance-by-member-list';
import { parsePatientName } from 'display-components/balance-by-member-list/balance-by-member-list.helpers';
import MakePayment from 'display-components/payment-history/make-payment-modal/make-payment-modal.component';
import TransactionList from 'display-components/payment-history/transaction-list';
import UserBalance from 'display-components/payment-history/user-balance';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import Pagination from 'components/pagination/pagination';
import { TransactionCardVariant } from 'components/transaction-card/transaction-card.types';

import { accountProfileSelector } from 'state/account/account.selectors';
import { familyProfileDependentsSelector } from 'state/family-profile/family-profile.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';
import { sumCurrentBalance } from 'state/payments/payments.helpers';
import { paymentsV2GetPaymentHistoryRoutine } from 'state/payments/payments.routines';
import { paymentIsLoadingHistorySelector, paymentsStateSelector } from 'state/payments/payments.selectors';

import { noop } from 'util/function';
import { convertToTitleCase } from 'util/string';

import './payment-history.style.scss';

const PAGE_SIZE = 10;

const PaymentHistory = ({ data }: { data: GatsbyTypes.PaymentHistoryDataQuery }) => {
    // Hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Local State
    const [activeTab, setActiveTab] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    // Selectors
    const { paymentHistoryV2 } = useSelector(paymentsStateSelector);
    const isLoadingPaymentHistoryData = useSelector(paymentIsLoadingHistorySelector);
    const familyProfileData = useSelector(familyProfileDependentsSelector);
    const profileObject = useSelector(accountProfileSelector);

    // Memos
    const hasNextPage = useMemo(
        () => paymentHistoryV2 && paymentHistoryV2.results.length === PAGE_SIZE,
        [paymentHistoryV2]
    );

    const ownerBalance = useMemo(() => {
        if (!paymentHistoryV2) return undefined;

        // Provisional solution until BE returns the sum of all balances correctly
        return sumCurrentBalance(paymentHistoryV2) as number;
    }, [paymentHistoryV2]);

    // TODO: This pagination uses the same logic of the previous Payment history page.
    // This could be updated to match the new component.
    const handleNextPageClick = () => {
        setCurrentPage(currentPage + 1);
    };

    // TODO: This pagination uses the same logic of the previous Payment history page.
    // This could be updated to match the new component.
    const handlePrevPageClick = () => {
        const nextPage = currentPage - 1;
        setCurrentPage(nextPage < 1 ? 1 : nextPage);
    };

    const fetchPaymentsHistory = useCallback(() => {
        dispatch(
            paymentsV2GetPaymentHistoryRoutine.trigger({
                page: currentPage.toString(),
                pageSize: PAGE_SIZE.toString(),
                includeAging: 'false',
                onSuccess: () => noop,
                onFailure: () => noop
            })
        );
    }, [dispatch, currentPage]);

    // TODO: This pagination uses the same logic of the previous Payment history page.
    // This could be updated to match the new component.
    const handlePaymentSuccess = () => {
        setCurrentPage(1);
        // Ensure the state update for currentPage is reflected before fetching new data
        setCurrentPage((prevPage) => {
            if (prevPage !== 1) {
                fetchPaymentsHistory(); // Fetch only after setting the new page.
            }
            return 1;
        });
    };

    useEffect(() => {
        fetchPaymentsHistory();
    }, [fetchPaymentsHistory]);

    const parsePaymentHistoryV2Result = (data: Record<string, any>) => {
        return {
            transactionInfo: {
                DMEItem: data.dmeItem,
                DMEOrder: data.dmeOrder,
                EPostScriptId: data.ePostScriptId,
                GLDebitCredit: data.glDebitCredit,
                GLPaymentNumber: data.glPaymentNumber,
                GLPostAmount: data.glPostAmount,
                GLPostDatetime: data.glPostDatetime,
                GLPostNote: data.glPostNote,
                GLPostStatusDesc: data.glPostStatusDesc,
                GLPostStatusNum: data.glPostStatusNum,
                GLPostType: data.glPostType,
                GLPostUser: data.glPostUser,
                epostPatientNum: data.epostPatientNum,
                familyId: data.familyId,
                orderPaymentCardMonth: data.orderPaymentCardMonth,
                orderPaymentCardMonthNum: data.orderPaymentCardMonthNum,
                orderPaymentCardNumber: data.orderPaymentCardNumber,
                orderPaymentCardSeqNum: data.orderPaymentCardSeqNum,
                orderPaymentCardTypeDesc: data.orderPaymentCardTypeDesc,
                orderPaymentCardTypeNum: data.orderPaymentCardTypeNum,
                orderPaymentCardYear: data.orderPaymentCardYear,
                patientName: data.patientName,
                displayDateTime: data?.displayDateTime,
                displayDate: data?.displayDate,
                paidUsingCredit: data.paidUsingCredit,
                accountsReceivableType: data.AccountsReceivableType,
                accountsReceivableStatus: data.AccountsReceivableStatus
            },
            user: parsePatientName(data.patientName),
            userType: 'caregiver' // WIP - need helper to determine this (same as Balance By User)
        };
    };

    const getAllPaymentHistory = useCallback(() => {
        if (paymentHistoryV2) {
            const getDependentsResults = paymentHistoryV2.dependents
                .filter((dep) => dep.results.length > 0)
                .map((a) => a.results)
                .flat();

            const mergeResults = [...paymentHistoryV2.results, ...getDependentsResults];

            return mergeResults.map((mr) => parsePaymentHistoryV2Result(mr));
        }
        return [];
    }, [paymentHistoryV2]);

    const getBalanceByMemberData = useCallback(() => {
        if (!profileObject?.isCaregiver) return [];

        if (!paymentHistoryV2) return [];

        const { patientFirstName, patientLastName, isCaregiver } = profileObject;

        return [paymentHistoryV2, ...paymentHistoryV2.dependents].map((currentValue) => {
            const findFamilyData = familyProfileData.find(
                (item) => item.ePostPatientNum === currentValue.epostPatientNum
            );

            return findFamilyData
                ? {
                      epostPatientNum: findFamilyData.ePostPatientNum,
                      currentBalance: currentValue.currentBalance,
                      patientName: convertToTitleCase(findFamilyData.familyMemberName),
                      isCaregiver: false
                  }
                : {
                      epostPatientNum: currentValue.epostPatientNum,
                      currentBalance: currentValue.currentBalance,
                      patientName: convertToTitleCase(`${patientFirstName} ${patientLastName}`),
                      isCaregiver
                  };
        });
    }, [paymentHistoryV2, familyProfileData, profileObject]);

    useEffect(() => {
        fetchPaymentsHistory();
    }, []);

    const handleMakePaymentByMemberModal = (patientName: string, amount: number, epostPatientNum: string) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: false,
                            dependentName: patientName,
                            amountDue: amount,
                            epostPatientNum
                        }}
                        fetchPaymentData={() => fetchPaymentsHistory()}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const handleMakePaymentFullBalanceModal = (isCaregiver?: boolean) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: isCaregiver ? profileObject?.isCaregiver : false,
                            amountDue: ownerBalance as number,
                            epostPatientNum: profileObject?.epostPatientNum as string,
                            isTotalPayment: profileObject?.isCaregiver ? true : false
                        }}
                        fetchPaymentData={() => fetchPaymentsHistory()}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const sections = useMemo(() => {
        const balanceByMembersData = getBalanceByMemberData();

        const balanceByMember = {
            heading: t('pages.profile.balanceByMember.eyebrowText'),
            children: (
                <div className="balance-container">
                    <BalanceByMemberList onClickPay={handleMakePaymentByMemberModal} members={balanceByMembersData} />
                </div>
            ),
            suppressChildrenContainer: true
        };

        const paymentHistory = {
            heading: t(`pages.profile.paymentHistory.heading`),
            children: (
                <div className="payment-history-container">
                    <TransactionList
                        variant={TransactionCardVariant.PAYMENT}
                        transactions={getAllPaymentHistory()}
                        activeTab={activeTab}
                        onNavigate={setActiveTab}
                    />
                    {/* TODO: As the new component needs a difeerent type of pagination, this must be replaced.
                     This pagination compone nte reuse the old logic to dont break the UI */}
                    <Pagination
                        currentPage={currentPage}
                        isNextPageDisabled={!hasNextPage}
                        onNextPageClick={handleNextPageClick}
                        onPreviousPageClick={handlePrevPageClick}
                    />
                </div>
            ),
            suppressChildrenContainer: true,
            showMemberTabs: true,
            activeTab: activeTab,
            onTabItemChange: setActiveTab
        };

        const userBalance = {
            children: (
                <UserBalance
                    value={ownerBalance}
                    handleOnClick={handleMakePaymentFullBalanceModal}
                    isLoading={isLoadingPaymentHistoryData}
                    skeletonHeight={75}
                />
            ),
            suppressChildrenContainer: true
        };

        // Temporary loadings, to avoid incorrect rendering while the dependents API is loading
        // once the API has loaded the name and lastName fields, this code will no longer be needed.
        if ((profileObject?.isCaregiver && !familyProfileData.length) || isLoadingPaymentHistoryData) {
            const createMockLoading = (height: number) => ({
                children: <BalanceByMemberList members={[]} onClickPay={noop} isLoading skeletonHeight={height} />,
                suppressChildrenContainer: true
            });

            if (profileObject?.isCaregiver) {
                return [
                    createMockLoading(75), // LoadingBalance
                    createMockLoading(200), // LoadingMembers
                    createMockLoading(500) // LoadingTransactions
                ];
            }

            return [
                createMockLoading(75), // LoadingBalance
                createMockLoading(500) // LoadingTransactions
            ];
        }

        if (balanceByMembersData?.length === 0) {
            return [userBalance, paymentHistory];
        }

        return [userBalance, balanceByMember, paymentHistory];
    }, [
        t,
        activeTab,
        isLoadingPaymentHistoryData,
        paymentHistoryV2,
        familyProfileData,
        profileObject,
        getBalanceByMemberData,
        handleMakePaymentByMemberModal,
        handlePaymentSuccess
    ]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.paymentHistory.eyebrowText`)}
            title={t(`pages.profile.paymentHistory.title`)}
            suppressChildrenContainer={true}
            sections={sections}
        />
    );
};

export default PaymentHistory;

export const query = graphql`
    query PaymentHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
