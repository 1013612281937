import { PaymentHistoryV2Response } from 'types/payment-history';

export const sumCurrentBalance = (history: PaymentHistoryV2Response): number => {
    return (
        history.currentBalance +
        (history?.dependents?.reduce(
            (acc, dependent) => acc + sumCurrentBalance(dependent as PaymentHistoryV2Response),
            0
        ) || 0)
    );
};
